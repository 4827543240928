<messages>["../Invoice"]</messages>

<template>
  <v-btn
    text
    :href="docUrl (value.id)">
    {{ $t ('label.downloadAllDocs') }}
    <v-icon>get_app</v-icon>
  </v-btn>
</template>

<script>
  import InvoiceDownloadHelper from './InvoiceDownloadHelper'

  export default {
    name: 'InvoiceDownload',

    mixins: [InvoiceDownloadHelper],

    props: {
      value: {
        type: Object,
        required: true
      }
    }
  }
</script>
