<messages>["../Invoice"]</messages>

<template>
  <v-data-table
    hide-default-footer
    class="elevation-1"
    :headers="headers"
    :items="value"
    :items-per-page="-1">
    <template #headerCell="props">
      {{ props.header.text }}
    </template>
    <template #item="props">
      <tr>
        <td>
          <a
            :href="docUrl (props.item.id)"
            target="_blank"
            v-text="props.item.fileName"/>
        </td>
        <td
          v-for="p in docFields.filter (it => it !== 'fileName')"
          :key="p"
          v-text="props.item[p]"/>
        <td>
          <invoice-amount-info
            :net-amount="props.item.netAmount"
            :gross-amount="props.item.grossAmount"
            :currency="props.item.currency"/>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
  import {mapGetters} from 'vuex'

  import InvoiceAmountInfo from './InvoiceAmoutInfo'

  export default {
    name: 'InvoiceDocTable',

    components: {
      InvoiceAmountInfo
    },

    props: {
      value: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        docFields: [
          'fileName',
          'mimeType',
          'size',
          'title'
        ]
      }
    },

    computed: {
      ...mapGetters ({
        operationLink: 'request/operationLink'
      }),

      headers () {
        const headerItem = it => ({
          text: this.$t (`label.${it}`),
          value: it
        })

        return [
          ...this.docFields.map (headerItem),
          {
            text: this.$t ('label.invoiceAmount'),
            value: 'invoiceAmount'
          }
        ]
      }
    },

    methods: {
      docUrl (id) {
        return this.operationLink ({
          op: 'account/get-invoice-doc',
          params: {
            id
          }
        })
      }
    }
  }
</script>

<style scoped>
th {
  text-align: left;
}
</style>
