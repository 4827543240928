<messages>["../Invoice"]</messages>

<template>
  <v-card
    tile
    class="elevation-1">
    <v-card-title>
      <strong v-t="`view.data.title`"/>
    </v-card-title>
    <v-card-text class="pa-6">
      <name-value-block :items="items"/>
    </v-card-text>
  </v-card>
</template>

<script>
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import ClientLink from '@/app/core/components/ClientLink'

  import ResetInvoiceFailed from './ResetInvoiceFailed'

  export default {
    name: 'InvoiceData',

    components: {
      NameValueBlock
    },

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      items () {
        const v = prop => this.value[prop]

        const nv = (prop, val) => ({
          name: this.$t (`label.${prop}`),
          value: val || v (prop)
        })

        const dv = (prop) => nv (prop, this.formatDateShort (v (prop), true))

        return [
          {
            ...nv ('clientId'),
            slotValue: this.$createElement (ClientLink, {
              props: {
                id: v ('clientId')
              }
            })
          },
          dv ('creationDate'),
          dv ('from'),
          dv ('to'),
          nv ('serial'),
          {
            name: this.$t ('label.state'),
            value: this.$t (`state.${this.value.state}`),
            ...this.value.state === 'Failed'
              ? {
slotValue: this.$createElement (ResetInvoiceFailed, {
                props: {
                  invoiceId: this.value.id
                },
                on: {
                  success: () => this.$emit ('input', {
                    ...this.value,
                    state: 'Pending'
                  })
                }
              })
}
              : {}
          }
        ]
      }
    }
  }
</script>
