<messages>["../Invoice"]</messages>

<template>
  <v-tooltip bottom>
    <template #activator="{on}">
      <span v-if="hasData" v-on="on">
        {{ formatMoneyAmount (grossAmount, currency) }}
      </span>
      <span v-else>{{ EmptyMark }}</span>
    </template>
    <div v-if="hasData">
      <span>{{ $t ('label.netAmount') }}: </span>
      <span>{{ formatMoneyAmount (netAmount, currency) }}</span>
    </div>
  </v-tooltip>
</template>

<script>
  import {EmptyMark} from '@/app/utils/string'

  export default {
    props: {
      netAmount: {
        type: Number,
        default: undefined
      },

      grossAmount: {
        type: Number,
        default: undefined
      },

      currency: {
        type: Number,
        default: undefined
      }
    },

    data () {
      return {
        EmptyMark
      }
    },

    computed: {
      hasData () {
        return this.netAmount && this.grossAmount && this.currency
      }
    }
  }
</script>
