<messages>["./Invoice"]</messages>

<template>
  <base-layout mw1>
    <v-col cols="12">
      <!-- "invoice does not exist" alert -->
      <v-alert
        type="error"
        :value="!isLoading && !invoice">
        {{ $t (`view.invalidInvoice`, {id}) }}
      </v-alert>

      <!-- invoice details -->
      <v-card v-if="invoice">
        <v-card-title primary-title>
          <div class="text-h5 mr-1">
            <span v-text="invoice.invoiceNumber"/>
          </div>
        </v-card-title>
        <v-card-text>
          <invoice-data v-model="invoice"/>
          <invoice-docs
            v-if="invoice.docs && invoice.docs.length"
            :value="invoice"/>
          <p
            v-else-if="invoice.state === 'Pending'"
            v-t="`view.docs.pending`"
            class="pa-4"/>
        </v-card-text>
        <v-card-actions
          v-if="invoice"
          class="pa-4">
          <v-spacer/>
          <back-button/>
          <template v-if="actionButtons.length">
            <v-btn
              v-for="item in actionButtons"
              :key="item.invoiceNumber"
              :disabled="item.disabled"
              @click="processActionButton (item)">
              {{ $t (item.labelKey) }}
              <v-icon class="pl-2">
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import InvoiceData from '@/app/pages/Account/components/InvoiceData'
  import InvoiceDocs from '@/app/pages/Account/components/InvoiceDocs'
  import BackButton from '@/app/core/components/BackButton'

  export default {
    name: 'InvoiceView',

    components: {
      InvoiceDocs,
      BaseLayout,
      InvoiceData,
      BackButton
    },

    props: {
      id: {
        type: [Number, String],
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        invoice: null
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'hasAllOfPermissions'
      ]),

      isAdmin () {
        return this.hasAllOfPermissions (['ViewAllObjects'])
      },

      actionButtons () {
        const buttons = []

        if (this.isAdmin && this.invoice) {
          const btn = (action, icon, labelKey, disabled = false) => ({
            action,
            itemId: this.invoice.id,
            icon,
            labelKey,
            disabled
          })

          switch (this.invoice.state) {
            case 'Public':
              buttons.push (btn ('revoke', 'undo', 'general.button.revoke'))
              break

            case 'InternalReview':
              buttons.push (
                btn ('create', 'replay', 'general.button.regenerate', !this.invoice.canBeRegenerated),
                btn ('publish', 'public', 'general.button.publish')
              )
              break
            case 'Revoked':
              buttons.push (
                btn ('create', 'replay', 'general.button.regenerate', !this.invoice.canBeRegenerated)
              )
          }
        }

        return buttons
      }
    },

    watch: {
      id: {
        handler () {
          this.loadInvoice ()
        },
        immediate: true
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton ({action, itemId: id}) {
        this.fetchData ({
          op: `account/${action}-invoice`,
          params: {
            id
          },
          cb: () => {
            this.$router.back ()
          }
        })
      },

      /**
       * Load the invoice.
       */
      loadInvoice () {
        this.isLoading = true
        this.invoice = null

        this.fetchData ({
          op: 'account/load-invoice',
          params: {
            id: this.id
          },
          cb: data => {
            this.invoice = data.invoiceData
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
