<messages>["../Invoice"]</messages>

<template>
  <v-card
    tile
    class="elevation-1">
    <v-card-title>
      <strong v-t="`view.docs.title`"/>
      <v-spacer/>
      <invoice-download :value="value"/>
    </v-card-title>
    <v-card-text class="pa-6">
      <invoice-doc-table :value="value.docs"/>
    </v-card-text>
  </v-card>
</template>

<script>
  import InvoiceDocTable from '@/app/pages/Account/components/InvoiceDocTable'
  import InvoiceDownload from '@/app/pages/Account/components/InvoiceDownload'

  export default {
    name: 'InvoiceDocs',

    components: {InvoiceDownload, InvoiceDocTable},

    props: {
      value: {
        type: Object,
        required: true
      }
    }
  }
</script>
