<messages>["../Invoice"]</messages>

<template>
  <v-row align="center">
    <v-col>
      {{ $t ('state.Failed') }}
      <v-tooltip bottom>
        <template #activator>
          <v-btn
            v-if="isAdmin"
            text icon small
            :loading="isLoading"
            @click="resetInvoice">
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
          <span v-t="'general.button.reset'"/>
        </template>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
  import {mapMutations, mapActions, mapGetters} from 'vuex'

  export default {
    props: {
      invoiceId: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        isLoading: false
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'hasAllOfPermissions'
      ]),

      isAdmin () {
        return this.hasAllOfPermissions (['ViewAllObjects'])
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      resetInvoice () {
        this.isLoading = true

        this.fetchData ({
          op: 'account/reset-invoice',
          params: {
            id: this.invoiceId
          },
          cb: () => {
            this.displaySuccessMessage (this.$t ('view.resetSuccess'))
            this.$emit ('success')
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
